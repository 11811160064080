import abilities from '../utils/ability'

export default class LoginService {
  // eslint-disable-next-line class-methods-use-this
  getAbility(userData) {
    let testTools = []

    if (userData.id === 405514) {
      testTools = [
        {
          subject: 'TestTools',
          action: 'read',
        },
      ]
    }

    if (userData.permission_type === 'manager') {
      return [...abilities.manager, ...testTools]
    }

    if (userData.permission_type === 'sales') {
      return [...abilities.sales, ...testTools]
    }

    if (userData.permission_type === 'success') {
      return [...abilities.success, ...testTools]
    }

    if (userData.permission_type === 'relationship') {
      return [...abilities.relationship, ...testTools]
    }

    if (userData.permission_type === 'service_qualification') {
      return [...abilities.service_qualification, ...testTools]
    }

    if (userData.permission_type === 'management_processes') {
      return [...abilities.management_processes, ...testTools]
    }

    if (userData.permission_type === 'root') {
      return [...abilities.root, ...testTools]
    }

    return [...abilities.manager, ...testTools]
  }

  // eslint-disable-next-line class-methods-use-this
  getRootAbility() {
    return abilities.manager
  }
}
